.new-service-container {
    background-color: transparent;
    width: 38vw;
    height: 40vw;
    max-width: 340px;
    max-height: 350px;
    min-width: 250px;
    min-height: 265px;
    margin-bottom: 15px;
}

.new-service-container-fields {
    border: none;
    background-color: #DDDDDD;
}

.new-service-container-icon {
    border-radius: 5px;
}

.add-service-icon:hover {
    color: green !important;
    cursor: pointer;
}

.new-service-img {
    width: 100%;
    height: 100%;
}

.input-label {
    flex: 1;
    border: 1px dashed black;
    height: 90px;
}

.input-label:hover {
    border: 1px dashed #AAAAAA;
    color: #AAAAAA;
    cursor: pointer;
}

.label {
    width: 80px;
}

.input {
    flex: 1;
}