.contact-line-text {
    background-color: transparent;
    border-color: transparent;
}

.contact-line-input {
    margin-top: 5px;
    margin-bottom: 15px;
    color: #000000;
    height: 32px;
    padding: 6px 16px;
    border: 1px solid #7F7F7F;
    outline: none;
}

.contact-line-textarea {
    height: 190px;
}

.contact-line-input:hover, .contact-line-input:focus {
    border: 1px solid #000000;
}

.contact-line-input::placeholder {
    color: #C4C4C4;
}

.contact-line-input:hover::placeholder, .contact-line-input:focus::placeholder  {
    color: #000000;
}
