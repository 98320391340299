@font-face {
  font-family: '__Montserrat_5';
  src: url('style/fonts/montserrat-italic.eot');
  src: local('☺'), url('style/fonts/montserrat-italic.woff') format('woff'), url('style/fonts/montserrat-italic.svg') format('svg');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: '__Montserrat_5';
  src: url('style/fonts/montserrat-bold.eot');
  src: local('☺'), url('style/fonts/montserrat-bold.woff') format('woff'), url('style/fonts/montserrat-bold.svg') format('svg');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: '__Montserrat_5';
  src: url('style/fonts/montserrat-regular.eot');
  src: local('☺'), url('style/fonts/montserrat-regular.woff') format('woff'), url('style/fonts/montserrat-regular.svg') format('svg');
  font-weight: 400;
  font-style: normal;
}

body {
  margin: 0;
  font-family: '__Montserrat_5';
  color: #000000;
}

/*Esconde scrollbar mantendo funcionalidade - Obs: Não funciona para Firefox*/
body::-webkit-scrollbar {
  display: none;
}

body {
  -ms-overflow-style: none;
}
/****/

#root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

.font-montserrat {
  font-family: '__Montserrat_5';
}

.text-center {
  text-align: center;
}

.text-left-important {
  text-align: left !important;
}

.center-content {
  width: 80%;
  min-width: 600px;
}

.weight-400 {
  font-weight: 400;
}

.font-12 {
  font-size: 12px;
  line-height: 14px;
}

.font-14 {
  font-size: 14px;
  line-height: 17px;
}

.font-15 {
  font-size: 15px;
  line-height: 15px;
}

.font-16 {
  font-size: 16px;
  line-height: 21px;
}

.font-18 {
  line-height: 22px;
  font-size: 18px;
}

.font-20 {
  font-size: 20px;
  line-height: 24px;
}

.font-22 {
  line-height: 26px;
  font-size: 22px;
}

.font-30 {
  font-size: 30px;
  line-height: 36px;
}

.font-36 {
  font-size: 36px;
  line-height: 43px;
}

.weight-700 {
  font-weight: 700;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.flex-1 {
  flex: 1;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.row {
  flex-direction: row;
}

.column {
  flex-direction: column;
}

.content-center {
  justify-content: center;
}

.content-start {
  justify-content: flex-start;
}

.content-end {
  justify-content: flex-end;
}

.content-stretch {
  justify-content: stretch;
}

.content-between {
  justify-content: space-between;
}

.content-evenly {
  justify-content: space-evenly;
}

.align-start {
  align-items: flex-start;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: flex-end;
}

.align-stretch {
  align-items: stretch;
}

.self-center {
  align-self: center;
}

.self-end {
  align-self: flex-end;
}

.self-stretch {
  align-self: stretch;
}

.self-justify-center {
  justify-self: center;
}

.full-fixed {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
}

.full-absolute {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
}

.padding-15 {
  padding: 15px;
}

.padding-10-15 {
  padding: 10px 15px;
}

.absolute-top-left {
  position: absolute;
  top: 5px;
  left: 5px;
}

.absolute-top-right {
  position: absolute;
  top: 5px;
  right: 5px;
}

.icon-btn {
  padding: 5px;
  cursor: pointer;
  z-index: 1;
  -webkit-transition: color 0.2s ease;
  -moz-transition: color 0.2s ease;
  -o-transition: color 0.2s ease;
  transition: color 0.2s ease;
}

.icon-btn:hover {
  color: #AAAAAA !important;
}

.edit-container {
  padding: 20px 0;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.edit-container textarea {
  max-height: 80px;
}

.edit-text {
  margin: 0;
  text-align: center;
  word-wrap: break-word;
  overflow-x: hidden;
}

.edit-input {
  outline: none;
  border: none;
  background-color: transparent;
  text-align: center;
  resize: none;
}

.component-focus {
  position: relative;
}

.component-focus:hover {
  cursor: pointer;
}

.component-focus::after {
  position: absolute;
  display: block;
  content: '';
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0);
  -webkit-transition: background-color 0.2s ease;
  -moz-transition: background-color 0.2s ease;
  -o-transition: background-color 0.2s ease;
  transition: background-color 0.2s ease;
}

.component-focus:hover::after {
  background-color: rgba(255, 255, 255, 0.2);
}

input {
  outline: none;
  border: none;
  background-color: transparent;
}

.link-text:hover {
  cursor: pointer;
  color: -webkit-link;
}

@media all and (max-width: 800px) {
  .center-content {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }

  .edit-container {
    padding: 20px 0;
  }

  .banner-text {
    font-size: 23px;
  }
}
