@font-face{font-family:AntDesign;font-style:normal;font-weight:400;src:url(./AntDesign.ttf) format('truetype')}
@font-face{font-family:Entypo;font-style:normal;font-weight:400;src:url(./Entypo.ttf) format('truetype')}
@font-face{font-family:EvilIcons;font-style:normal;font-weight:400;src:url(./EvilIcons.ttf) format('truetype')}
@font-face{font-family:Feather;font-style:normal;font-weight:400;src:url(./Feather.ttf) format('truetype')}
@font-face{font-family:FontAwesome;font-style:normal;font-weight:400;src:url(./FontAwesome.ttf) format('truetype')}
@font-face{font-family:Foundation;font-style:normal;font-weight:400;src:url(./Foundation.ttf) format('truetype')}
@font-face{font-family:Ionicons;font-style:normal;font-weight:400;src:url(./Ionicons.ttf) format('truetype')}
@font-face{font-family:MaterialCommunityIcons;font-style:normal;font-weight:400;src:url(./MaterialCommunityIcons.ttf) format('truetype')}
@font-face{font-family:MaterialIcons;font-style:normal;font-weight:400;src:url(./MaterialIcons.ttf) format('truetype')}
@font-face{font-family:Octicons;font-style:normal;font-weight:400;src:url(./Octicons.ttf) format('truetype')}
@font-face{font-family:SimpleLineIcons;font-style:normal;font-weight:400;src:url(./SimpleLineIcons.ttf) format('truetype')}
@font-face{font-family:Zocial;font-style:normal;font-weight:400;src:url(./Zocial.ttf) format('truetype')}
