.app-footer {
    background-color: #000000;
    padding: 15px;
    color: white;
}

.app-footer-content {
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
}

@media all and (max-width: 800px) {
    .app-footer-content {
        flex-direction: column;
        align-items: center;
    }
}
