#login-modal-component {
    background-color: rgba(0, 0, 0, 0.6);
}

.login-close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
}

.login-close-icon:hover {
    cursor: pointer;
    color: #555555 !important;
}

.login-container {
    position: relative;
    width: 300px;
    min-height: 350px;
    background-color: #FFFFFF;
    border-radius: 5px;
}

.login-title {
    align-self: center;
    text-align: center;
    margin-top: 20px;
}

.login-container-input {
}

.login-input {
    border: none;
    border-bottom: 1px solid #CCCCCC;
    padding-left: 15px;
    padding-bottom: 5px;
    margin-bottom: 30px;
    outline: none;
}

.login-btn {
    margin-bottom: 10px;
    background-color: #44795E;
    border-radius: 5px;
    color: #FFFFFF;
}

.login-btn-content:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.2);
    color: #DDDDDD;
}