.app-header {
    background-color: #000000;
    height: 80px;
}

.app-header:hover {
    opacity: 1 !important;
}

.header-logo {
    width: 163px;
    height: 62px;
    align-self: center;
    cursor: pointer;
}

.header-menu-item {
    position: relative;
    height: 45px;
    padding: 0 15px;
    color: #FFFFFF;
    text-align: center;
}

.header-menu-item:hover {
    cursor: pointer;
    color: #CCCCCC;
}

.space-span {
    margin-left: 5px;
}

.header-icon {
    display: none;
}

@media all and (min-width: 701px) {
    #header-menu {
        display: flex !important;
        opacity: 1 !important;
    }

    .header-menu-item::after {
        position: absolute;
        left: 0;
        bottom: -5px;
        display: block;
        content: '';
        width: 100%;
        border-bottom: 3px solid #FFFFFF;
        -webkit-transform: scaleX(0);
        -ms-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transition: -webkit-transform 300ms ease-in-out;
        transition: transform 300ms ease-in-out;
    }

    .header-menu-item:hover::after {
        -webkit-transform: scaleX(1);
        -ms-transform: scaleX(1);
        transform: scaleX(1);
    }
}

@media all and (max-width: 700px) {
    .app-header {
        padding: 0 15px;
        border-bottom: 2px solid #FFFFFF;
    }

    .header-icon {
        padding: 0 15px;
        margin-right: -15px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    .header-icon:hover {
        cursor: pointer;
        background-color: #888c94;
    }

    .app-header-content {
        position: relative;
    }

    #header-menu {
        display: none;
    }

    #header-menu {
        flex-direction: column;
        justify-content: center;
        align-items: stretch;
        position: absolute;
        top: 80px;
        left: -15px;
        width: calc(100% + 30px);
        background-color: #000000;
    }

    .header-menu-item {
        background-color: #000000;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding-left: 60px;
    }

    .header-menu-item:hover {
        background-color: rgba(255, 255, 255, 0.2);
    }
}
