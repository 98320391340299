.list-modal-content {
    min-width: 350px;
    border-radius: 5px;
    padding-top: 30px;
}

.list-modal-content-item {
    padding: 10px;
    text-align: center;
    border-radius: 5px;
    color: #000000;
    text-transform: uppercase;
}

.list-modal-content-item:hover{
    background-color: #DDDDDD;
    cursor: pointer;
}
