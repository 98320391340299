.invest-section-item {
    width: 280px;
    height: 250px;
    position: relative;
    margin-bottom: 30px;
    border-top: 2px solid transparent;
    box-shadow: 2px 5px 8px rgba(0,0,0,0.13);
    padding: 10px 0 30px;
    background-color: #FFFFFF;
}

.invest-section-item-row {
    width: 230px;
}

.invest-section-item-row {
    margin: 10px 0;
}

.invest-section-item-button {
    border-style: solid;
    color: #5F5F5F;
    border-color: #FFD000;
    border-width: 1px;
    width: 93px;
    max-width: 93px;
    height: 38px;
    max-height: 38px;
}

.invest-section-item:hover {
    border-top: 2px solid #FFD000;
}

.invest-section-item-button:hover {
    background-color: #FFD000;
    cursor: pointer;
}

.invest-section-item-label {
    width: 80px;
}

.invest-section-item-label {
    font-weight: bold;
}

.invest-section-item-text {
    text-align: right;
}
