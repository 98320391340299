.company-container {
    background-color: rgba(255, 255, 255, 0.8);
    width: 340px;
    height: 450px;
    margin-bottom: 20px;
    margin-left: 20px;
}

.company-input-title {
    background-color: #DDDDDD;
    height: 40px;
    padding: 0 0 0 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    font-size: 20px;
    color: #5F5F5F;
    font-weight: bold;
    margin: 10px 0 0 0;
}

.company-img {
    width: 100%;
}

.company-img-unavailable {
    position: relative;
    top: -10px;
    color: #000000;
}

.company-img, .company-img-unavailable {
    height: 200px;
    background-color: #FFFFFF;
}

.company-img-unavailable {
    border: 1px dashed transparent;
}

.company-img-unavailable:hover {
    border: 1px dashed #AAAAAA;
    color: #AAAAAA;
    cursor: pointer;
}

@media all and (max-width: 800px) {
    .company-container {
        margin: 10px 0;
    }
}
