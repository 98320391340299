#service-section {
    background-color: rgba(204, 204, 204, 0.1);
    padding: 85px 15px 0 15px;
    margin: 0 auto;
    min-height: 300px;
}

@media all and (max-width: 800px) {
    #service-section {
        padding: 10px 0;
        margin-top: 75px;
    }
}
