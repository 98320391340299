#contact-section {
    justify-content: center;
    align-items: center;
    background-color: #000000;
}

.contact-section-content {
    border-top: 35px solid black;
    border-bottom: 35px solid black;
    border-left: 20px solid black;
    border-right: 20px solid black;
    padding: 40px;
    background-color: #FFFFFF;
}

.contact-title {
    margin-bottom: 60px;
}

.send-btn {
    align-self: center;
    color: #FFFFFF;
    background-color: #000000;
    margin-top: 15px;
    width: 300px;
    height: 30px;
    border-radius: 2px;
    -webkit-transition: background-color 0.5s ease, color 0.5s ease;
    -moz-transition: background-color 0.5s ease, color 0.5s ease;
    -o-transition: background-color 0.5s ease, color 0.5s ease;
    transition: background-color 0.5s ease, color 0.5s ease;
}

.send-btn:hover {
    background-color: #039BA3;
    color: #EFEFEF;
    cursor: pointer;
}

@media all and (min-width: 801px) {
    .contact-content {
        width: 500px;
    }
}

@media all and (max-width: 800px) {
    #contact-section {
        justify-content: stretch;
        align-items: stretch;
    }

    .contact-title {
        align-self: center;
    }

    .contact-section-content {
        padding: 40px 10px;
        align-items: stretch;
    }
}
