#toggle-banner {
    height: 100vh;
    max-height: 1980px;
    width: 100%;
    max-width: 2560px;
    position: relative;
}

.toggle-banner-content {
    flex: 1;
}

.banner-img {
    position: absolute;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
}

#banner-text {
    color: #FFFFFF;
    margin-bottom: 100px;
    letter-spacing: 5px;
    z-index: 2;
}

.slider-icon-container {
    width: 50px;
    z-index: 2;
}

.slider-icon-container:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.1);
}

.slider-icon, .upload-banner-image {
    text-shadow: 0 0 4px #000000;
}

.upload-banner-image {
    position: absolute;
    top: 85px;
    right: 60px;
    z-index: 23;
}

@media all and (max-width: 700px) {
    #toggle-banner {
        height: 400px;
        margin-top: 75px;
    }

    #banner-text {
        font-size: 24px;
        line-height: 29px;
        margin-bottom: 30px;
    }
}
