.partner-container {
    background-color: rgba(255, 255, 255, 0.8);
    width: 340px;
    height: 350px;
    margin-bottom: 20px;
    margin-left: 20px;
}

.partner-input-title {
    background-color: #DDDDDD;
    height: 40px;
    padding: 0 0 0 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    font-size: 20px;
    color: #5F5F5F;
    font-weight: bold;
    margin: 10px 0 0 0;
}

.partner-input-link {
    padding: 0 0 0 10px;
    font-size: 18px;
    color: #000000;
}

.partner-img {
    width: 100%;
}

.partner-img-unavailable {
    position: relative;
    top: -20px;
    color: #FFFFFF;
}

.partner-img, .partner-img-unavailable {
    height: 150px;
}

.partner-img-unavailable {
    border: 1px dashed transparent;
    background-color: transparent;
}

.partner-img-unavailable:hover {
    border: 1px dashed #AAAAAA;
    color: #AAAAAA;
    cursor: pointer;
}

@media all and (max-width: 800px) {
    .partner-container {
        margin: 10px 0;
    }
}
