.our-services-item-content {
    width: 330px;
    margin-bottom: 20px;
}

.our-services-item-image {
    width: 80px;
}

.our-services-item-image-container {
    border: 2px solid transparent;
    padding: 0;
    border-radius: 5px;
}

.our-services-item-image-container:hover {
    border: 2px dashed #AAAAAA;
}

.our-services-item-content span {
    padding-right: 10px;
}

.our-services-item-content span i {
    top: 0;
    padding: 0;
}
